<template>
  <div class="send-science">
    <h2>任务管理</h2>
    <div class="header-btn">
      <div class="header-tip">按条件筛选</div>
      <div class="header-checkbox">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAll">全选</el-checkbox>
        <el-checkbox-group v-model="checkedCities" @change="handleChange">
          <el-checkbox label="1">添加仪器分类</el-checkbox>
          <el-checkbox label="2">添加科技资源分类</el-checkbox>
        </el-checkbox-group>
      </div>

      <el-button type="primary" size="mini" @click="screenClick">筛选</el-button>
      <el-button size="mini" @click="reset">重置</el-button>
    </div>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column align="center" prop="type" label="任务类型"></el-table-column>
        <el-table-column align="center" prop="category_name" label="分类名称"></el-table-column>
        <el-table-column align="center" prop="organ_name" label="所属机构"></el-table-column>
        <el-table-column align="center" prop="user_name" label="发起人"></el-table-column>
        <el-table-column align="center" prop="created_at" label="发起时间"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.range"
              @change="rangeChange($event,scope.row)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <page class="page" :total="page.total" @currentPageChange="currentPageChange" />
    </div>
  </div>
</template>
<script>
import page from "@/components/PageComponents";
import { success, warning, error } from "@/utils/notification";
export default {
  name: "sendScience",
  components: { page },
  data() {
    return {
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      tableData: [], //表格数据
      page: { current_page: 0, per_page: 0, total: 0, last_page: 0 },
      nowPage: 1, //当前page
      options: [
        { id: 0, label: "不予以使用" },
        { id: 1, label: "可供全网机构使用" },
        { id: 2, label: "仅限当前机构使用" }
      ]
    };
  },
  created() {
    this.getTasks([], 1);
  },
  methods: {
    //超管任务管理人任务列表
    async getTasks(screen, page) {
      const { data: res } = await this.$superHttp.post(
        this.URL.superUrl.adminTasks,
        { screen, page }
      );
      this.tableData = res.data.data;
      this.tableData.forEach(item => {
        if (item.type == 1) {
          item.type = "科研仪器";
        } else {
          item.type = "科技资源";
        }
      });
      this.page = res.data.meta;
      console.log(res, "超管任务管理人任务列表");
    },
    //全选
    handleCheckAll(e) {
      this.checkedCities = e ? ["1", "2"] : [];
    },
    //选择
    handleChange(e) {
      this.checkedCities = e;
    },
    //筛选
    screenClick() {
      this.getTasks(this.checkedCities, 1);
    },
    //重置
    reset() {
      this.checkedCities = [];
      this.getTasks(this.checkedCities, 1);
    },
    //分页
    currentPageChange(e) {
      this.nowPage = e;
      this.getTasks(this.checkedCities, e);
    },
    //选择改变
    async rangeChange(e, data) {
      const { data: res } = await this.$superHttp.post(
        this.URL.superUrl.adminTasks + data.id + "/range",
        { range: e }
      );
      success("状态修改成功");
      this.getTasks(this.checkedCities, this.nowPage);
      console.log(res, "选择改变");
    }
  }
};
</script>
<style lang="less" scoped>
.send-science {
  .header-btn {
    margin: 20px 0;
    background-color: rgb(221, 219, 219);
    padding: 15px;
  }
  .header-checkbox {
    margin: 15px 0;
  }
  .page {
    text-align: center;
    margin-top: 10px;
  }
}
</style>